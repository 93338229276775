import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LanguageService } from '../../../services/language-service.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-user-instruction',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './user-instruction.component.html',
  styleUrl: './user-instruction.component.scss'
})
export class UserInstructionComponent implements OnInit {
  @Input({
    required: true
  }) testDetails!: any;

  @Output() refresh = new EventEmitter<void>();
  @Output() start = new EventEmitter<string>();
  showAgeSelection: boolean = false;
  selectedAge: string = '13';

  constructor(public languageService: LanguageService) {
  }

  ngOnInit(): void {
    if (this.testDetails.test_type_id === 4) {
      this.showAgeSelection = true;
      
    }
  }

  refreshTestDetails() {
    this.refresh.emit()
  }

  startTest() {
    this.start.emit(this.selectedAge)
  }


}
