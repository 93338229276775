import { Component, EventEmitter, Inject, Input, NgZone, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { LanguageService } from '../../../services/language-service.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ApiResponse } from '../../../model';
import { environment } from '../../../../environments/environment';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-payment-dialog',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './payment-dialog.component.html',
  styleUrl: './payment-dialog.component.scss'
})
export class PaymentDialogComponent implements OnInit {
  @Input({
    required: true
  }) testTypeId!: number;

  @Output() success = new EventEmitter<void>();
  //routeParams: any;
  token: any;

  orderData: any;

  basicAmount: number = 0;
  amount: number = 0;
  discount: number = 0;
  promoApplied: string = '';
  formData: any = {};
  quantity: number = 1;
  // submit = "Proceed to pay";
  testResultData: any = '';

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private AS: AuthService, private zone: NgZone, public languageService: LanguageService) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.fetchTestDetails()
    }
  }

  fetchTestDetails() {
    const param = { testTypeId: this.testTypeId, languageId: <string>this.AS.commandinDB('get', 'selectedLanguage') };

    this.AS.getTestDetailById(param).subscribe({
      next: (res: ApiResponse<any>) => {
        console.log("paymentData", res);
        this.testResultData = res['data'];
        setTimeout(() => {
          this.basicAmount = parseInt(this.testResultData['amount']);
          this.amount = (this.basicAmount - this.discount);
          this.initSummary();
        }, 300);
      },
      error: (err) => {
        this.AS.alert('Internet Error! please check your internet');
        console.error('Err:', err);
      },
      complete: () => {
        // Optional: Add any logic that needs to be executed after successful retrieval of test details
      }
    });
  }


  //-------------------------------------------------------------------


  payWithRazor(val: any, price: number) {
    const options: any = {
      // Move this key to secure area
      key: environment.pg_key,
      amount: price, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: 'Payment for result', // company name or product name
      description: '',  // product description
      image: '../assets/images/newimages/logo.png', // company logo or product image
      order_id: val, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a'
      },
      config: {
        display: {
          hide: [
            {
              method: 'paylater'
            }
          ],
          preferences: {
            show_default_blocks: true,
          },
        },
      },
    };
    options.handler = ((response: any, error: any) => {
      options.response = response;
      // call your backend api to verify payment signature & capture transaction
      this.zone.run(() => this.verifyOrder(response))
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
      this.AS.alert('Transaction cancelled.');
    });
    const rzp = new this.AS.nativeWindow.Razorpay(options);
    rzp.open();
    rzp.on('payment.failed', function (response: any) {
      alert(response.error.code + "If your balance is debited from your account, then contact our support center");
    })
  }

  //-------------------------------------------------------------------

  callCoupenAPI() {
    if (this.formData.coupen == undefined) {
      this.AS.alert('Please enter coupon first then apply');
      return;
    }

    let payload = {
      "code": this.formData.coupen,
      "amount": this.basicAmount
    };
    this.AS.createOrderCoupen(payload).subscribe({
      next: (res) => {
        const checkRes = this.AS.checkRes(res);

        if (checkRes === 'data') {
          if (res['status'] === 'success') {
            // This is final amount after discount.
            this.amount = parseInt(res['data']['payable_amount']);
            this.promoApplied = this.formData.coupen;
            this.discount = res['data']['discount_amount'];
            this.basicAmount = parseInt(res['data']['total_amount']);
            this.quantity = res['data']['test_credit']
            this.AS.alert('Coupon Applied');
            this.initSummary();
          } else {
            this.AS.alert('something went wrong please try again');
          }
        } else {
          this.AS.alert('No Coupon found');
        }
      },
      error: (err) => {
        this.AS.alert('Internet Error! please check your internet');
        console.error('Err:', err);
      },
      complete: () => {
        // Optional: Add any logic that needs to be executed after successful coupon creation (if applicable)
      }
    });


  }

  initSummary() {
    // this.basicAmount = parseInt(this.testResultData['amount']);

    // this.amount = (this.basicAmount - this.discount);

    // if (this.discount == this.basicAmount) {
    //   this.submit = "Go to result";
    // }
  }

  initOrder() {
    try {
      if (this.testResultData['amount'] == undefined) {
        this.AS.alert('No amount found to pay, Please try to submit test again');
        return;
      }
      if (this.discount < this.basicAmount) {
        console.log(this.formData.paymentGateway);
        this.setOrder();
      } else {
        this.discount_100_percent_API();
      }

    } catch (ex) {
      this.AS.alert('something went wrong, Please try again');
    }

  }

  setOrder() {

    let payload = {
      "testTypeId": this.testTypeId,
      "promo": this.promoApplied || null
    }
    this.AS.createOrder(payload, this.formData.paymentGateway).subscribe({
      next: (res: ApiResponse<any>) => {
        console.log('creating order res:', res);
        const checkRes = this.AS.checkRes(res);

        if (checkRes === 'data') {
          if (res['status'] === 'success') {
            this.orderData = res['data']['order'];
            console.log('order', this.orderData);

            const orderId = this.orderData.order_id;
            const price = this.orderData.amount;
            this.payWithRazor(orderId, price);
          } else {
            this.AS.alert('something went wrong please try again');
          }
        } else {
          this.AS.alert('No Data found in our directory');
        }
      },
      error: (err) => {
        this.AS.alert('Internet Error! please check your internet');
        console.error('Err:', err);
      },
      complete: () => {
        // Optional: Add any logic that needs to be executed after successful order creation (if applicable)
      }
    });
  }

  verifyOrder(pgResponse: any) {
    let payload = {
      "orderId": pgResponse.razorpay_order_id
    }
    this.AS.verifyOrder(payload).subscribe(res => {

      console.log('verify order res:', res);
      let checkRes = this.AS.checkRes(res);
      if (checkRes == 'data') {
        if (res['status'] == 'success') {

          this.AS.alert('Payment Successfull');
          this.paymentSuccess();
          // this.router.navigate(['quiz/' + this.testTypeId]);
        } else {
          this.AS.alert('something went wrong please try again');
        }

      } else {
        this.AS.alert('No Data found in our directory');
      }
    }, (err) => {

      this.AS.alert('Internet Error! please check your internet');
      console.log('Err:', err);
    })
  }

  discount_100_percent_API() {

    let payload = {
      "testTypeId": this.testTypeId,
      "promo": this.promoApplied || null
    }
    this.AS.createOrderCoupen100(payload).subscribe(res => {

      let checkRes = this.AS.checkRes(res);
      if (checkRes == 'data') {
        if (res['status'] == 'success') {
          // this.AS.alert('100% Discount applied, proceeding to the test page');

          this.paymentSuccess();

        } else {
          this.AS.alert('something went wrong please try again');
        }

      } else {
        this.AS.alert('No Data found in our directory');
      }



    }, (err) => {

      this.AS.alert('Internet Error! please check your internet');
      console.log('Err:', err);
    })

  }


  paymentSuccess(){
    this.success.emit();
  }

}
