<div>
    
    <app-user-instruction *ngIf="quizState.instruction==true && (testDetails.test_category == 'user' || testDetails.test_category == 'hiring')" [testDetails]="testDetails" (refresh)="refreshTestDetails()" (start)="startTest($event)"></app-user-instruction>
    <app-psycologist-instruction *ngIf="quizState.instruction==true && testDetails.test_category == 'psycologist'" [testDetails]="testDetails" (refresh)="refreshTestDetails()" (start)="startTest($event)"></app-psycologist-instruction>
    
    <div class="sec-qa quiz-slider" *ngIf="quizState.questions==true">
        <div class="container mt-4">
            <div class="header">
                <h3 class=" text-black font-weight-medium">{{testDetails.question_header}}</h3> <img
                    [src]="testDetails.question_img">
            </div>
            <div class="timer mt-1" *ngIf="testDetails.timer!=null">⌛<span *ngIf="isNegativeTimer">
                    -</span><span>{{secondsToHms(timeLeft)}}</span>
            </div>
            <div class="progress-indicator">
                <div class="circle">{{currentSlideInfo.currentSlideIndex + 1}}</div>
                <div class="progress-bar">
                    <div class="bar"
                        [style.width]="((currentSlideInfo.currentSlideIndex + 1) / quizAllQuestions.length * 100) + '%'">
                    </div>
                </div>
                <div class="circle">{{quizAllQuestions.length}}</div>
            </div>

            <div id="myCarousel" class="carousel slide align-center mt-2" data-bs-interval="false" data-bs-ride="false"
                data-bs-touch="false">
                <div class="carousel-inner">
                    <div *ngFor="let q of quizAllQuestions;let i = index" [id]="i"
                        class="carousel-item item {{i==currentSlideInfo.currentSlideIndex?'active':''}}">
                        <div class="question-txt my-4">
                            <div class="row justify-content-center">
                                <div class="col-auto">
                                    <h4 [innerHTML]="q.question"></h4>
                                </div>
                            </div>
                            <img [src]="q.question_img.url" class="question-img" *ngIf="processImg(q,q.question_img)">
                        </div>
                        <!-- <div class="row justify-content-center"
                            *ngIf="getObjectData('keys',q.question_options.options).length > 0 && quizState.optionView=='normal'">
                            <div class="col-12">
                                <div class="option-box"
                                    *ngFor="let qOptions of getObjectData('values',q.question_options.options); let ii = index"
                                    (click)="selectAnswer(q.id,ii)"
                                    [ngClass]="{'option-selected': q.useranswer.answerIndex == ii}">
                                    <div *ngIf="!processImg(q,qOptions.img)">{{qOptions.value}}</div>
                                    <img [src]="qOptions.img.url" class="option-img" *ngIf="processImg(q,qOptions.img)">
                                </div>
                            </div>
                        </div> -->
                        <div class="row justify-content-center"
                            *ngIf="getObjectData('keys',q.question_options.options).length > 0 && quizState.optionView=='normal'">
                            <div class="col-12">
                                <div class="option-box"
                                    *ngFor="let qOptions of getObjectData('values',q.question_options.options); let ii = index"
                                    (click)="selectAnswer(q.id,ii)"
                                    [ngClass]="{'option-selected': q.useranswer.answerIndex == ii}">

                                    <div class="option-content">
                                        <div class="option-text" *ngIf="!processImg(q,qOptions.img)">
                                            {{qOptions.value}}
                                        </div>
                                        <img [src]="qOptions.img.url" class="option-img"
                                            *ngIf="processImg(q,qOptions.img)">
                                        <div class="option-number">{{ii + 1}}</div> <!-- Add this for option number -->
                                    </div>

                                </div>
                            </div>
                        </div>
                        <ul class=" list-unstyled"
                            *ngIf="getObjectData('keys',q.question_options.options).length > 0 && quizState.optionView=='bar'">
                            <li (click)="selectAnswer(q.id,ii)" [ngClass]="{'selected': q.useranswer.answerIndex == ii}"
                                *ngFor="let qOptions of getObjectData('values',q.question_options.options); let ii = index">
                                {{qOptions.value}}</li>
                        </ul>

                        <!-- <div class="slider-container"
                            *ngIf="getObjectData('keys',q.question_options.options).length > 0 && quizState.optionView=='bar'">
                            <ul class="option-bar" [ngStyle]="{'background': barBackground}">
                                <li (click)="selectAnswer(q.id, ii, getObjectData('keys',q.question_options.options).length)"
                                    [ngClass]="{'selected': q.useranswer.answerIndex == ii}"
                                    *ngFor="let qOptions of getObjectData('values', q.question_options.options); let ii = index">
                                    {{qOptions.value}}
                                </li>
                            </ul>
                        </div> -->

                    </div>
                </div>
                <button class="carousel-control-next right carousel-control" id="next-click-btn"
                    style="visibility: hidden;" (click)="getCurrentSlideInfo()" type="button"
                    data-bs-target="#myCarousel" data-bs-slide="next" aria-hidden="true">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>

            <div *ngIf="quizState.questions==true && quizState.showSubmitBtn==true">
                <div class="submit_test text-right">
                    <button (click)="submitTest()" name="endtest" title="Submit" class="btn-submit-new mt-4"
                        id="endtest">{{languageService.transalateText('SUBMIT TEST')}}</button>
                </div>
            </div>

        </div>
    </div>

</div>