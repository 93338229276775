import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { LanguageService } from '../../services/language-service.service';
import { NavigationEnd, Router } from '@angular/router';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ApiResponse } from '../../model';
import { LanguageData } from '../home/home.dt';

@Component({
  selector: 'app-app-header',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'] // Fixed to styleUrls
})
export class AppHeaderComponent implements OnInit {
  socialUser: any = {};
  loggedIn: boolean = false;
  isBrowser: boolean;
  isSidePanelOpen: boolean = false;
  languages: Array<LanguageData> = [];

  selectedLanguage: string;

  constructor(
    private AS: AuthService,
    private router: Router,
    public languageService: LanguageService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && this.isBrowser) {
        // This code will only run in the browser
        // Example: Google Analytics tracking code or any other browser-specific actions
        // gtag('config', 'G-2BHQEK24SF', {
        //   'page_path': event.urlAfterRedirects
        // });
      }
    });

    this.languages = [{
      id: Number(this.AS.DefaultLanguage),
      language: "English",
      is_active: true,
      tag: 'en'
    }]
    this.selectedLanguage = <string>this.AS.commandinDB('get', 'selectedLanguage');
    // If user has not select any language yet. Than set default language english;
    if (!this.selectedLanguage){
      this.selectedLanguage = this.AS.DefaultLanguage;
      this.setUserLanguage();
    }
  }

  ngOnInit(): void {
    
    this.getLanguages();
    if (this.isBrowser) {
      this.getLocation();
      if (window.location.hostname !== "testwebsite.codepiercer.in" && window.location.hostname !== "localhost") {
        console.log = function () { };
      }
    }
  }

  toggleSidePanel() {
    this.isSidePanelOpen = !this.isSidePanelOpen;
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position) {
            this.AS.commandinDB('set', 'userLocation', `${position.coords.latitude},${position.coords.longitude}`);
          }
        },
        (error) => console.log(error)
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  getLanguages() {
    this.AS.getLanguages().subscribe({
      next: (res: ApiResponse<Array<LanguageData>>) => {
        // console.log('Languages:', res);
        let checkRes = this.AS.checkRes(res);
        if (checkRes === 'data') {
          if (res['status'] === 'success') {
            this.languages = res['data'];
            return;
          }
        }
        this.AS.alert("Language API failed");
      },
      error: (err) => {
        if (err.error.message) {
          this.AS.alert(err.error.message);
        } else {
          this.AS.alert('Internet Error! please check your internet');
        }
        // console.log('Err:', err);
      },
      complete: () => {
        // Optional: Add any finalization logic if needed
      }
    });
  }

  setUserLanguage() {
    if(Number(this.selectedLanguage)>2){
      this.AS.alert("Tests will be available soon in this language. Stay tuned!")
      return
    }
    this.AS.commandinDB('set', 'selectedLanguage', this.selectedLanguage);
    if(this.isBrowser){
      window?.location.reload()
    }
    
  }
}
