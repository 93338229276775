// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  google_key: '228241616182-9ekms7gdb3inr1clfrbh56grahd79o79.apps.googleusercontent.com',
  fb_key: '550057806350544',
  // server_url: 'https://appapi.dishakiran.com',
  server_url: 'https://qaapi.dishakiran.com',
  // server_url: 'http://localhost:3030',
  api_version: 'v3',
  pg_key: 'rzp_test_BkaFyjdeKZr14w',
  key: 'key',
  firebase: {
    apiKey: 'YOUR_API_KEY',
    authDomain: 'YOUR_AUTH_DOMAIN',
    databaseURL: 'YOUR_DATABASE_URL',
    projectId: 'YOUR_PROJECT_ID',
    storageBucket: "",
    messagingSenderId: 'YOUR_MESSAGING_SENDER_ID'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
