import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../services/language-service.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ApiResponse } from '../../model';
import { HomePageData, LanguageData } from './home.dt';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})

export class HomeComponent implements OnInit {

  constructor(private AS: AuthService, private route: ActivatedRoute,
    private router: Router, public languageService: LanguageService) {
      
  }

  ngOnInit(): void {
    
  }

  goToUserHome(){
    this.router.navigate(['user-home'])
  }

  goToPsycoHome(){
    this.router.navigate(['psycologist-home'])
  }

  
}
