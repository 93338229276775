import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

declare var intlTelInput: any;

@Directive({
  selector: '[appIntlTelInput]',
  standalone: true
})
export class IntlTelInputDirective implements AfterViewInit {
  @Input() initialCountry: string = 'in'; // Default to India
  @Output() fullPhoneNumber = new EventEmitter<string>(); // Emits full phone number

  private phoneInput: any;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.phoneInput = intlTelInput(this.el.nativeElement, {
      initialCountry: this.initialCountry,
      separateDialCode: true,
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js'
    });

    // Bind to 'countrychange' event and input changes
    this.el.nativeElement.addEventListener('countrychange', () => {
      this.emitFullPhoneNumber();
    });

    this.el.nativeElement.addEventListener('input', () => {
      this.emitFullPhoneNumber();
    });
  }

  // Emit full phone number (with country code) in international format
  private emitFullPhoneNumber() {
    const fullNumber = this.phoneInput.getNumber(); // Get full number in international format
    this.fullPhoneNumber.emit(fullNumber); // Emit to parent component
  }
}