import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../services/language-service.service';
import { ApiResponse } from '../../model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {

  routeParams: any;
  user: any = {
    photoUrl: null
  };
  profileData: any;
  profileResults: any = [];
  unpaidResult: any;
  testList: any;
  referURL: any;
  DIALOG_TYPE = {
    HTML: 1,
    PDF: 2
  }
  activeTab: string = 'profile';

  constructor(private AS: AuthService, private route: ActivatedRoute,
    private router: Router, public languageService: LanguageService) {

  }

  ngOnInit(): void {
    console.log('ngon init of profile component')
    this.getUserDetails();
    this.getProfileData();
    // let referal_code = this.AS.commandinDB('get', 'referal_code');

  }

  switchTab(tab: string) {
    this.activeTab = tab;
  }

  loader(par = false) {
    if (par) {
      this.AS.screenLoader('show');
    } else {
      this.AS.screenLoader('hide');
    }
  }


  async getUserDetails() {
    this.user = await this.AS.userCTRL('get');
  }

  loggedInUserUpdateWithoutSocialLogin() {
    this.user.name = this.profileData.data.user.user_fullname;
    this.user.username = this.profileData.data.user.user_username;
  }

  downloadResultPDF(url: string, resultId = null) {
    if (url == null || url == undefined) {
      if (resultId != null) {
        this.AS.alert('Please wait your result PDF is downloading');
        this.getResultPDFdata(resultId);
      } else {
        this.AS.alert('Something went wrong!!');
        console.log('ERR: no resultId found');
      }

    } else {
      this.openDialog(url, this.DIALOG_TYPE.PDF)
    }

  }

  viewResult(url: string, resultId = null) {
    if (url == null || url == undefined) {
      if (resultId != null) {
        this.AS.alert('Please wait your result is downloading');
        this.getResultHTMLdata(resultId);
      } else {
        this.AS.alert('Something went wrong!!');
        console.log('ERR: no resultId found');
      }

    } else {
      this.openDialog(url, this.DIALOG_TYPE.HTML);
    }

  }

  openDialog(data: string, _type: number) {
    if (_type == this.DIALOG_TYPE.PDF) {
      window.location.href = data;
      return;
    } else {
      this.AS.fetchHTML({ url: data }).subscribe((res: ApiResponse<any>) => {
        let checkRes = this.AS.checkRes(res);
        if (checkRes == 'data') {
          if (res['status'] == 'success') {
            if (res['data']['html'] == null || res['data']['html'] == undefined) {
              this.AS.alert('Fatal Err: Something went wrong');
              console.log('Fatal Err: No PDF url');
            } else {
              let html = res['data']['html']
              html = html.replace('<body onload="window.print()">', '<body>')
              let win = window.open('', '_blank');
              // Create an iframe with fixed dimensions
              let iframe = `
                <html>
                  <head>
                    <style>
                      body, html { margin: 0; padding: 0; height: 100%; overflow: hidden; }
                      iframe { border: none; width: 100%; height: 100%; }
                    </style>
                  </head>
                  <body>
                    <iframe width="100%" height="100%" srcdoc="${html.replace(/"/g, '&quot;')}"></iframe>
                  </body>
                </html>
              `;
              if (this.isMobile()) {
                iframe = `
                <html>
                  <head>
                    <style>
                      body, html { margin: 0; padding: 0; height: 100%; overflow: hidden; }
                      iframe { border: none; width: 1000px; height: 100%; }
                    </style>
                  </head>
                  <body>
                    <iframe width="100%" height="100%" srcdoc="${html.replace(/"/g, '&quot;')}"></iframe>
                  </body>
                </html>
              `;
              }


              win?.document.write(iframe);
            }
          } else {
            this.AS.alert('Something went wrong, Please try again');
          }
        } else {
          this.AS.alert('No data found on server');
        }
      })
    }
    // }

  }

  isMobile(): boolean {
    return window.innerWidth <= 768;
    // You can also use a more specific user agent check for mobile devices
    // return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  async getProfileData() {
    this.loader(true);

    this.AS.getProfileData().subscribe({
      next: (res: ApiResponse<any>) => {
        console.log('profile Res:', res);
        let checkRes = this.AS.checkRes(res);
        if (checkRes === 'data') {
          if (res['status'] === 'success') {
            this.profileData = res;
            this.profileResults = this.profileData.data.results;
            this.profileResults = this.profileResults?.map((result: any) => {
              result.title = result.title?.split('-')[0]
              result.r_time = this.convertUTCToIST(result.created_on)
              return result
            })
            this.unpaidResult = this.profileData.data.unpaidResult;
            this.loggedInUserUpdateWithoutSocialLogin();
          } else {
            this.AS.alert('Something went wrong, please refresh the page.');
          }
        } else {
          this.AS.alert('Something went wrong, please refresh the page.');
        }
        this.loader();
      },
      error: (err) => {
        if (err.error?.message) {
          this.AS.alert(err.error.message);
        } else {
          this.AS.alert('Internet Error! Please check your internet connection.');
        }
        console.log('Err:', err);
        this.loader();
      },
      complete: () => {
        // Optional: If you have any cleanup or additional logic after the observable completes.
      }
    });
  }

  convertUTCToIST(utcDateTime: string) {
    // Create a Date object from the UTC datetime string
    // Create a new Date object for IST by adding the offset to the UTC Date
    const istDate = new Date(utcDateTime);

    // Format the IST datetime as desired
    const formattedISTDateTime = istDate.toLocaleString('en-IN', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });

    return formattedISTDateTime;
  }


  async getResultHTMLdata(resultId: any) {
    this.loader(true);
    this.AS.viewResult(resultId).subscribe({
      next: (res: ApiResponse<any>) => {
        // console.log('pdf url api res:', res);
        let checkRes = this.AS.checkRes(res);
        if (checkRes === 'data') {
          if (res['status'] === 'success') {
            if (res['data']['html_url'] == null || res['data']['html_url'] === undefined) {
              this.AS.alert('Fatal Err: Something went wrong');
              console.log('Fatal Err: No PDF url');
            } else {
              this.openDialog(res['data']['html_url'], this.DIALOG_TYPE.HTML);
            }
          } else {
            this.AS.alert('Something went wrong, Please try again');
          }
        } else {
          this.AS.alert('No data found on server');
        }
        this.loader();
      },
      error: (err) => {
        if (err.error?.message) {
          this.AS.alert(err.error.message);
        } else {
          this.AS.alert('Internet Error! Please check your internet connection.');
        }
        console.log('Err:', err);
        this.loader();
      },
      complete: () => {
        // Optional: If you have any cleanup or additional logic after the observable completes.
      }
    });

  }

  async getResultPDFdata(resultId: number) {
    this.loader(true);
    let credential = {
      "resultId": resultId
    };
    // console.log('pdf api call credential', credential);
    this.AS.getProfileResultPDFData(credential).subscribe({
      next: (res: ApiResponse<any>) => {
        // console.log('pdf url api res:', res);
        let checkRes = this.AS.checkRes(res);
        if (checkRes === 'data') {
          if (res.status === 'success') {
            if (!res.data.pdf_url) {
              this.AS.alert('Fatal Err: Something went wrong');
              console.log('Fatal Err: No PDF url');
            } else {
              this.openDialog(res.data.pdf_url, this.DIALOG_TYPE.PDF);
            }
          } else {
            this.AS.alert('Something went wrong, Please try again');
          }
        } else {
          this.AS.alert('No data found on server');
        }
        this.loader();
      },
      error: (err) => {
        if (err.error?.message) {
          this.AS.alert(err.error.message);
        } else {
          this.AS.alert('Internet Error! Please check your internet connection.');
        }
        console.log('Err:', err);
        this.loader();
      },
      complete: () => {
        // Optional: Add any finalization logic if needed
      }
    });



  }

  goToResultPage(resultId: number) {
    this.router.navigate(['result', resultId]);
  }


  logOut() {
    this.AS.loggedOut();
    // this.socialSite.loggedInViaCTRL();
    this.AS.alert('Logged Out');
    window.location.assign('/');
  }

  resetPassword() {
    this.AS.loggedOut();
    // this.socialSite.loggedInViaCTRL();
    this.AS.alert('Logged Out');
    this.router.navigate(['forgetpassword'])
  }

}
