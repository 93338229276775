import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ApiResponse } from '../../model';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PaymentDialogComponent } from '../dialogs/payment-dialog/payment-dialog.component';
import { UserInstructionComponent } from '../quiz/user-instruction/user-instruction.component';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-taketest',
  standalone: true,
  imports: [FormsModule, CommonModule, PaymentDialogComponent, UserInstructionComponent],
  templateUrl: './taketest.component.html',
  styleUrl: './taketest.component.scss'
})
export class TaketestComponent implements OnInit {
  timerEnabled: boolean = false;
  referCode: string = '';
  currentSlideInfo = { 'currentSlideIndex': 0 };
  currentSlideIndex = 0;
  testDetails: any = {};
  tmpDetails: any = {};
  paymentDone: boolean = false;
  // languages: any = [];
  selectedLanguage: string;
  selectedAge = '18';
  quizState = {
    'instruction': false,
    'questions': false,
    'submit': false,
    'showSubmitBtn': false,
    'showAgeSelection': false,
    'optionView': 'normal'
  }
  quizAllQuestions: Array<any> = [];
  isNegativeTimer = false;
  quizId = -1;
  alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
  resultRetry = false;
  timerCount: number = 0;
  timeLeft: number = 10; // in seconds
  timeInterval!: NodeJS.Timeout;
  barBackground: string = '';
  showPaymentModal: boolean = false;

  constructor(private alertService:AlertService, public languageService: LanguageService, private route: ActivatedRoute, private router: Router, private AS: AuthService) {
    this.selectedLanguage = <string>this.AS.commandinDB('get', 'selectedLanguage') || this.AS.DefaultLanguage;
  }

  ngOnInit(): void {
    this.extractTestType()
  }

  extractTestType() {
    this.route.params.subscribe(params => {
      // console.log('Route Params:', params); // Log the entire params object
      this.referCode = params['referCode']; // Extract routeData parameter from URL
      // console.log('Test Type:', this.testType);
      if (this.referCode) {
        this.fetchReferDetails();
      } else {
        this.AS.alert('Unable to fetch test');
      }

    });
  }

  async fetchReferDetails() {
    let payload = {
      referCode: this.referCode,
      languageId: this.selectedLanguage
    }
    this.AS.referDetails(payload).subscribe({
      next: (res: ApiResponse<any>) => {
        if (res['status'] != 'success') {
          this.AS.alert(<string>res['message'])
          return;
        }
        this.paymentDone = res['data']['paymentDone']
        this.testDetails = res['data']['testDetail']
        if (this.testDetails) {
          this.testDetails.disclaimer_body = this.testDetails.client_test_desc
        }
        let session: any = {};
        let loginData = res['data']['user']
        session['sessionId'] = loginData['token'];
        this.AS.sessionCTRL('set', session);
        this.AS.userCTRL('set', JSON.stringify(loginData));
        
        if (this.paymentDone) {
          this.initTest(); // Assuming this is a method
        } else {
          this.showPaymentModal = true
        }
      },
      error: (err: any) => {
        this.AS.alert('Internet Error! please check your internet');
        console.error('Err:', err);
      },
      complete: () => {
        // Optional complete callback
      }
    });
  }

  // async fetchReferDetails() {

  // }

  initTest() {
    this.currentSlideInfo = { 'currentSlideIndex': 0 };
    this.selectedAge = '13';
    this.quizAllQuestions = [];
    this.quizState = {
      'instruction': true,
      'questions': false,
      'submit': false,
      'showSubmitBtn': false,
      'showAgeSelection': false,
      'optionView': 'normal'
    }
    // this.testDetails = this.tmpDetails
    this.isNegativeTimer = false
    this.selectedLanguage = <string>this.AS.commandinDB('get', 'selectedLanguage');
    this.resultRetry = false;
  }

  closeModal() {
    if (this.paymentDone) {
      this.showPaymentModal = false;
    } else {
      this.AS.alert("Please complete payment first")
    }
  }

  paymentSuccess() {
    window.location.reload();
  }

  timerCTRL(currentTime: number) {
    if (currentTime == 0) {
      // if (this.timerCount == 0) {
      //   this.timeLeft = 60 * 60;
      //   this.timerCount++;
      //   this.isNegativeTimer = true
      //   this.startTimer();
      //   this.AS.alert('TIME is up!! you have 60 minutes to submit');
      // } else {
        this.router.navigate(['/']);
      // }
    }
  }


  startTimer() {
    this.timeInterval = setInterval(() => {
      if (this.timeLeft == 0) {
        this.pauseTimer();
        this.timerCTRL(this.timeLeft);
      } else if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 60;
      }

      
      if(this.timeLeft == 60){
        this.AS.alert("Only 1 minute is left to complete the test")
      }
    }, 1000)
  }

  pauseTimer() {
    clearInterval(this.timeInterval);
  }
  initTimer() {
    clearInterval(this.timeInterval);
    this.timeLeft = 0;
  }

  secondsToHms(d: any) {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor(d % 3600 / 60);
    let s = Math.floor(d % 3600 % 60);
    let hDisplay = h.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })

    let mDisplay = m.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })

    let sDisplay = s.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    })

    return mDisplay + ':' + sDisplay;
  }

  destroyTest() {
    this.initTimer();

  }

  getObjectData(param: string, data: any): any {

    if (param == 'keys') {
      return Object.keys(data);
    } else if (param == 'values') {
      return Object.values(data);
    }
  }

  startTest(selectedAge: string) {
    this.selectedAge = selectedAge;
    // Age logic for only test type 4 for now
    if (this.quizId == 4) {
      if (!this.selectedAge) {
        this.AS.alert('Please select age group first')
        return
      }
    } else {
      this.selectedAge = '13'
    }

    this.timeLeft = this.testDetails.timer;
    this.quizState['instruction'] = false;
    this.quizState['questions'] = true;
    let params = {
      testId: this.testDetails.id,
      testTypeId: this.testDetails.test_type_id,
      languageId: this.testDetails.language_id,
      age: this.selectedAge
    }
    this.getQuizQuestions(params);


  }

  async getQuizQuestions(params: any) {
    this.AS.getQuestions(params).subscribe({
      next: (res: ApiResponse<any>) => {
        const checkRes = this.AS.checkRes(res);

        if (checkRes === 'data') {
          if (res['status'] === 'success') {
            this.quizAllQuestions = res['data'];

            // Loop through questions and set useranswer and format question text
            this.quizAllQuestions.forEach(question => {
              question.useranswer = { answer: undefined, answerIndex: undefined };
              if (question.question) {
                question.question = question.question.replace(/(?:\r\n|\r|\n)/g, '<br>');
              }
            });

            if (this.testDetails.timer != null) {
              this.startTimer();
              this.timerEnabled = true
            }
          } else {
            this.initTest();
            this.AS.alert('something went wrong please try again');
          }
        } else {
          this.initTest();
          this.AS.alert('No questions for this test found');
        }
      },
      error: (err: any) => {
        this.initTest();
        this.AS.alert('Internet Error! please check your internet');
        console.error('Err:', err);
      },
      complete: () => {
        // Optional: Add any logic that needs to be executed after successful completion (if applicable)
      }
    });

  }


  // getNextButton(currentSlideIndex: number) {

  //   if (this.quizAllQuestions[currentSlideIndex]?.useranswer?.answerIndex != undefined) {
  //     if (currentSlideIndex < this.quizAllQuestions.length - 1) {
  //       return true;
  //     }

  //   }

  //   return false;
  // }
  getCurrentSlideInfo() {
    setTimeout((_: any) => {
      const currentSlideElement = document.querySelector('.quiz-slider .item.active');
      if (currentSlideElement) {
        const getCurrentSlideIndex = parseInt(currentSlideElement.getAttribute('id') || '0', 10);
        this.currentSlideInfo = { 'currentSlideIndex': getCurrentSlideIndex };
        console.log('Current slide is ', this.currentSlideInfo);
      }
    }, 700);
  }

  nextButtonClick(nextQuestion: any) {
    console.log('onNextButton current slide info->', this.currentSlideInfo, this.quizAllQuestions.length);
    if (this.currentSlideInfo.currentSlideIndex < this.quizAllQuestions.length - 1 && nextQuestion) {

      const button = document.getElementById('next-click-btn') as HTMLButtonElement | null;
      if (button) {
        button.click();
        // this.quizState.questionOrder = nextQuestion.question_order;
      }
      console.error('Test continue more question ahead');
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // Smooth scrolling effect
        });
        this.barBackground = ''
      }, 100);
    } else {
      console.error('Test completed need to submit test api');

      this.quizState.showSubmitBtn = true
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth' // Smooth scrolling effect
        });
      }, 100); // Adding a slight delay to ensure rendering is complete
    }
    // this.submitAnswerProcessing = false;
    if (nextQuestion) {

    }
  }


  // nextButtonClick() {
  //   const buttonElement = document.querySelector('.quiz-slider .right:not(.hide) > button') as HTMLButtonElement | null;

  //   if (buttonElement !== null) {
  //     buttonElement.click();
  //   } else {
  //     window.scrollTo(0, document.body.scrollHeight);
  //     this.quizState['showSubmitBtn'] = true;
  //   }
  // }

  selectAnswer(qId: number, optionIndex: number, totalOptions?: number) {

    let qIndex = this.quizAllQuestions.findIndex(x => x.id == qId);
    let answer = this.alphabet[optionIndex];
    //set answer
    this.quizAllQuestions[qIndex].useranswer = { 'answer': answer, 'answerIndex': optionIndex };
    const nextQuestion: any = this.quizAllQuestions[qIndex + 1];

    //Patch for backward compatibility
    this.quizAllQuestions[qIndex].isRightAnswer = this.quizAllQuestions[qIndex].question_options.options[answer].isRightAnswer || false
    this.getCurrentSlideInfo();
    setTimeout((_: any) => {
      this.nextButtonClick(nextQuestion);
    }, 0)
    if (this.quizState.optionView == 'bar') {
      this.updateBarBackground(optionIndex, Number(totalOptions))
    }
    // this.sendAnalytics(qIndex, this.quizAllQuestions.length)
  }

  updateBarBackground(optionIndex: number, totalOptions: number) {
    if (totalOptions) {
      const percentage = (optionIndex / (totalOptions - 1)) * 100;
      this.barBackground = `linear-gradient(to right, #2b9bfb ${percentage}%, #e0e0e0 ${percentage}%)`;
    }
  }

  canGoToNextSlide(currentSlideIndex: number): boolean {
    return this.quizAllQuestions[currentSlideIndex]?.useranswer?.answerIndex !== undefined &&
      currentSlideIndex < this.quizAllQuestions.length - 1;
  }

  // This function updates the current slide information
  updateCurrentSlideInfo() {
    const activeItem = document.querySelector('.quiz-slider .carousel-item.active');
    if (activeItem) {
      this.currentSlideIndex = parseInt(activeItem.getAttribute('id') || '0', 10);
    }
  }

  // This function is triggered when the user selects an answer
  // selectAnswer(qId: number, optionIndex: number) {
  //   const qIndex = this.quizAllQuestions.findIndex(q => q.id === qId);
  //   const answer = this.alphabet[optionIndex];

  //   // Set the selected answer
  //   this.quizAllQuestions[qIndex].useranswer = { answer, answerIndex: optionIndex };
  //   this.quizAllQuestions[qIndex].isRightAnswer = this.quizAllQuestions[qIndex].question_options.options[answer].isRightAnswer || false;

  //   // Update the current slide information
  //   this.updateCurrentSlideInfo();

  //   // Automatically go to the next slide if the answer is selected and it's not the last slide
  //   if (this.canGoToNextSlide(this.currentSlideIndex)) {
  //     this.goToNextSlide();
  //   } else {
  //     // Show the submit button on the last slide
  //     this.quizState.showSubmitBtn = true;
  //   }
  // }

  // This function controls the slide transition to the next slide
  goToNextSlide() {
    if (this.canGoToNextSlide(this.currentSlideIndex)) {
      this.currentSlideIndex++;
      const carousel = document.querySelector('#myCarousel') as HTMLElement;
      const nextButton = document.getElementById('next-click-btn') as HTMLButtonElement;

      if (nextButton) {
        nextButton.click(); // Simulate the click to slide to the next item
      }
    } else {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }

  generateSubmitAnswersList() {
    //without answer no submit
    if (this.quizAllQuestions.filter(x => x.useranswer.answer == undefined || x.useranswer.answerIndex == undefined).length > 0) {
      this.AS.alert('All Answers are mandatory');
      return null;
    }

    let payload: any = {
      "testId": this.testDetails.id,
      "testTypeId": Number(this.quizId),
      "submission": [],
      "languageId": this.testDetails.language_id,
      "referCode": this.referCode
    }

    this.quizAllQuestions.forEach((x, i) => {
      payload.submission.push(
        {
          "questionId": x.id,
          "question": "",
          "questionOrder": x.question_order != undefined ? x.question_order : null,
          "questionType": x.question_type != undefined ? x.question_type : null,
          "answer": x.useranswer.answer != undefined ? x.useranswer.answer : null,
          "isRightAnswer": x.isRightAnswer
        }
      )
    })

    // }

    return payload;

  }

  submitTest() {
    // this.AS.alert(this.languageService.transalateText("Do not close this message, While your result is generating... <br>Your result will also be available in the profile section"), false);
    let payload = this.generateSubmitAnswersList();
    if (payload == null) {
      return;
    }

    this.AS.submitQuizTest(payload).subscribe({
      next: (res: ApiResponse<any>) => {
        const checkRes = this.AS.checkRes(res);

        if (checkRes === 'data') {
          if (res['status'] === 'success') {
            this.alertService.showSuccessAlert("You have successfully finished your test, please Close the browser.");
            this.AS.loggedOut()
            this.router.navigate(['/blank'])
            // const win = window.open("", "_blank");
            // if (win) {
            //   let iframe = `
            //     <html>
            //       <head>
            //         <style>
            //           body, html { margin: 0; padding: 0; height: 100%; overflow: hidden; }
            //           iframe { border: none; width: 100%; height: 100%; }
            //         </style>
            //       </head>
            //       <body>
            //         <iframe width="100%" height="100%" srcdoc="${res['data']['html'].replace(/"/g, '&quot;')}"></iframe>
            //       </body>
            //     </html>
            //   `;
            //   if (this.isMobile()) {
            //     iframe = `
            //     <html>
            //       <head>
            //         <style>
            //           body, html { margin: 0; padding: 0; height: 100%; overflow: hidden; }
            //           iframe { border: none; width: 1000px; height: 100%; }
            //         </style>
            //       </head>
            //       <body>
            //         <iframe width="100%" height="100%" srcdoc="${res['data']['html'].replace(/"/g, '&quot;')}"></iframe>
            //       </body>
            //     </html>
            //   `;
            //   }

            //   win.document.write(iframe);
            //   this.AS.alertClose();
            //   this.router.navigate(['/profile']);
            // } else {
            //   console.error('Failed to open new window for result');
            //   this.router.navigate(['profile'])
            // }
          } else {
            if (!this.resultRetry) {
              this.submitTest();
              this.resultRetry = true;
              return; // Early exit after retry attempt
            }
            this.AS.alert('Test Submition failed, try again');
          }
        } else {
          this.AS.alert('empty data');
          this.AS.alert('Test Submission failed, try again');
        }
      },
      error: (err: any) => {
        this.AS.alert('Internet Error! please check your internet');
        console.error('Err:', err);
      },
      complete: () => {
        // Optional: Add any logic that needs to be executed after successful completion (if applicable)
      }
    });
  }

  isMobile(): boolean {
    return window.innerWidth <= 768;
    // You can also use a more specific user agent check for mobile devices
    // return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  processImg(x: any, img: string) {
    if (x.test_type_id == 3)
      return img ? true : false;
    else return undefined
  }
}
