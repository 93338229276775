import { Injectable } from '@angular/core';
import { LanguageService } from './language-service.service';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogConfig } from '@angular/material/dialog';
import { SuccessDialogComponent } from '../components/dialogs/success-dialog/success-dialog.component';
import { ErrorDialogComponent } from '../components/dialogs/error-dialog/error-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private languageService: LanguageService, private dialog: MatDialog) { }

  // Common method to show a browser alert box
  showSuccessAlert(message: string, title: string = 'Alert', subTitle: string = ''): void {
    if (typeof window !== 'undefined') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = { message: this.languageService.transalateText(message), title: this.languageService.transalateText(title), subTitle: this.languageService.transalateText(subTitle), icon: 'check_circle' };
      this.dialog.open(SuccessDialogComponent, dialogConfig); // This would be a custom alert dialog component
    }
  }

  showErrorAlert(message: string, title: string = 'Alert', subTitle: string = ''): void {
    if (typeof window !== 'undefined') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = { message: this.languageService.transalateText(message), title: this.languageService.transalateText(title), subTitle: this.languageService.transalateText(subTitle), icon: 'check_circle' };
      this.dialog.open(ErrorDialogComponent, dialogConfig); // This would be a custom alert dialog component
    }
  }

  // You can also add more custom alert handling methods if required
}