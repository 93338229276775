import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { LanguageService } from '../../services/language-service.service';
import { Router } from '@angular/router';
import { ApiResponse } from '../../model';
import { HomePageData, Tab } from '../home/home.dt';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-user-home',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './user-home.component.html',
  styleUrl: './user-home.component.scss'
})
export class UserHomeComponent implements OnInit {
  selectedLanguage: string;
  homePageData:HomePageData[] = [];
  noTest: boolean = false;
  COLOR_CODES = ['#32D3FF, #3F9AB3', '#6EEB83, #55BA66', '#8931F2, #AA63FF', '#FF6E30, #FF8B59', '#FF529A, #DD3D80', '#4BD8D2, #12B1AA']
  constructor(private AS: AuthService, public languageService: LanguageService, private router: Router){
    this.selectedLanguage = <string>this.AS.commandinDB('get', 'selectedLanguage');
  }

  ngOnInit(): void {
    this.initHomePage();
  }

  initHomePage() {
    
    // If user has not select any language yet. Than set default language english;
    if (!this.selectedLanguage) {
      this.selectedLanguage = this.AS.DefaultLanguage;
      this.setUserLanguage();
    }
    this.getHomePageData();
  }

  getHomePageData() {
    let params = {
      languageId: this.selectedLanguage
    }
    this.AS.getUserHomePageData(params).subscribe({
      next: (res: ApiResponse<Array<HomePageData>>) => {
        let checkRes = this.AS.checkRes(res);
        if (checkRes === 'data') {
          if (res['status'] === 'success') {
            this.homePageData = res['data'];
          } else {
            this.AS.alert('Something went wrong please refresh page');
          }
        } else {
          this.noTest = true
        }
        this.AS.screenLoader('hide');
      },
      error: (err) => {
        if (err.error?.message) {
          this.AS.alert(err.error.message);
        } else {
          this.AS.alert('Internet Error! please check your internet');
        }
        // console.log('Err:', err);
        this.AS.screenLoader('hide');
      },
      complete: () => {
        // Optional: You can handle any finalization logic here if needed
      }
    });
  }

  setUserLanguage() {
    this.AS.commandinDB('set', 'selectedLanguage', this.selectedLanguage);
  }

  getColor(index: number){
    let i = index % (this.COLOR_CODES.length);
    // return this.COLOR_CODES[i]
    return `linear-gradient(${this.COLOR_CODES[i]})`

  }

  goToQuizPage(testType: number) {
    if (!!testType) {
      this.router.navigate(['quiz', testType]);
    } else {
      this.AS.alert('No data to send to quiz');
      return;
    }
  }
}
