    <div class="header">
      <div class="heading1">{{languageService.transalateText("Unlock your Potential with our Psychometric Tests")}}</div>
      <div class="sub-heading">
        {{languageService.transalateText("Choose from a variety of tests to gain deeper insights into your abilities")}}
      </div>

      <!-- first test1 -->
       <div *ngIf="homePageData.length<=0 && noTest" class="text-center" style="font-size: 40px;margin-bottom: 150px; margin-top: 200px;">{{languageService.transalateText("No Test Found. Please Choose Different Language")}}</div>
      <div *ngFor="let category of homePageData; let i = index" class="assessment1">
        <div class="heading3">{{category.title}}</div>
        <div class="sub-heading2">
          {{category.desc}}
        </div>

        <div class="grid-container">
          <!-- Card 1st -->
          <div *ngFor="let test of category.tests;let ii=index;" class="card" [style.background-image]="getColor(ii)" (click)="goToQuizPage(test.test_type_id)">
            <div class="content">
              <div class="icon">
                <img src="{{test.icon_img}}" alt="{{test.title}}" width="25px" height="25px" />
              </div>
              <div id="text">

                <div class="icon-heading">
                  {{test.title}}
                </div>
                
                <div class="icon-content">
                  {{test.desc}}
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="break-line1"></div>
      </div>
    </div>
 