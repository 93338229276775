import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { LanguageService } from './language-service.service';
import { ApiResponse } from '../model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  //ss-------
  public DefaultLanguage = "1";
  // public selectedLanguage: string = this.DefaultLanguage;
  public API: any = [];
  public OneTimeSaveData: any;
  private dataSubject = new Subject<any>();
  private userLogin = new Subject<any>();
  public headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });


  API_URL = environment.server_url + '/api/v1/';
  API_V2_URL = environment.server_url + '/api/v2/';
  WEBSITE_URL = 'https://dishakiran.com';
  constructor(public http: HttpClient, private injector: Injector) {
  }

  getUserLoginState(): Subject<any> {
    return this.userLogin;
  }
  changeUserLoginState(data: any) {
    this.userLogin.next(data);
  }



  //ss-------------------------------------

  commandinDB(command: string, key: any = null, value: any = null) {
    if (command == "set") {
      localStorage.setItem(key, value);
    } else if (command == "get") {
      return localStorage.getItem(key);
    } else if (command == "clear") {
      if (key != null) {
        localStorage.removeItem(key);
      } else {
        localStorage.clear();
      }

    }
    return
  }

  sessionCTRL(command: string, session = null) {

    if (command == "set" && session != null) {
      console.log('Session Id', session);
      this.commandinDB("set", 'token', session['sessionId']);
    } else if (command == "get") {
      return this.commandinDB("get", 'token');
    } else if (command == "clear") {
      this.commandinDB("clear", 'token');
    }
    return
  }

  userCTRL(command: string, value: any = null) {

    if (command == "set" && value != null) {
      console.log('user', value);
      this.commandinDB("clear", 'user');
      this.commandinDB("set", 'user', value);
      this.changeUserLoginState(JSON.parse(value));
    } else if (command == "get") {
      let user = this.commandinDB("get", 'user');
      if (user != null || user != undefined) {
        return JSON.parse(user);
      }
      return null;
    } else if (command == "clear") {
      this.changeUserLoginState(null);
      this.commandinDB("clear", 'user');
    }


  }

  isLoggedIn() {
    let session = this.sessionCTRL('get');
    if (!!session) {
      if (!!session) {
        return true;
      }
    }
    return false;
  }

  async loggedOut() {
    let session = await this.sessionCTRL('get');

    if (!!session) {
      await this.sessionCTRL('clear');
      await this.userCTRL('clear');
    }

    return true;
  }



  async screenLoader(command: string) {

    // if (command == "show") {
    //   this.loader.start();
    // }
    // this.loader.stop();
  }

  alert(msg: string, closable = true, position = null, duration = 2000) {
    const languageService = this.injector.get(LanguageService);
    if (typeof document !== 'undefined') {
      this.modalFire(languageService.transalateText(msg), closable);
    }
  }

  alertConfirm(msg: string, msgType = null) {

  }

  getCurrentDateTime() {
    let today = new Date();
    let todayDateTime = {
      "date": today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear(),
      "time": today.getHours() + ":" + (today.getMinutes() + 1) + ":" + today.getSeconds()
    };
    return todayDateTime;
  }

  oneTimeSaveDataCTRL(command: string, data = null) {
    if (command == "get") {
      return this.OneTimeSaveData;
    } else if (command == "set") {
      this.OneTimeSaveData = [];
      this.OneTimeSaveData.push(data);
      return true;
    } else if (command == "clear") {
      this.OneTimeSaveData = undefined;
      return true;
    }
  }

  checkRes(json: any) {

    if (json.data == '' || json == '' || json.data == null || json.data == undefined) {
      console.log('API: DATA Empty', json.data);
      return 'empty';
    } else {
      return 'data';
    }
  }

  loggingIn(payload: any) {
    return this.http.post<ApiResponse<any>>(this.API_URL + 'users/login', payload, { headers: this.headers });
  }

  socialLoggingIn(payload: any) {
    return this.http.post<ApiResponse<any>>(this.API_URL + 'users/login/social', payload, { headers: this.headers });
  }

  guestLogin(payload: any) {
    return this.http.post<ApiResponse<any>>(this.API_URL + 'users/guest/login', payload);
  }

  register(payload: any) {
    return this.http.post<ApiResponse<any>>(this.API_URL + 'users', payload, { headers: this.headers });
  }

  psycologistRegister(payload: any) {
    return this.http.post<ApiResponse<any>>(this.API_URL + 'users/psycologistRegister', payload, { headers: this.headers });
  }

  guestRegister(payload: any) {
    return this.http.post<ApiResponse<any>>(this.API_URL + 'users/guest/register', payload, { headers: this.headers })
  }

  forgetPassword(payload: any) {
    return this.http.post<ApiResponse<any>>(this.API_URL + 'users/login/forgot', payload, { headers: this.headers });
  }

  confirmOtp(payload: any) {
    return this.http.post<ApiResponse<any>>(this.API_URL + 'users/login/confirm', payload, { headers: this.headers });
  }

  resetPassword(payload: any) {
    return this.http.post<ApiResponse<any>>(this.API_URL + 'users/login/reset', payload, { headers: this.headers });
  }

  submitQuizTest(payload: any) {
    return this.http.post<ApiResponse<any>>(this.API_V2_URL + 'tests/submit', payload, { headers: this.headers });
  }

  viewResult(resultId: string) {
    return this.http.get<ApiResponse<any>>(this.API_V2_URL + 'tests/viewResult?result_id=' + resultId, { headers: this.headers });
  }

  fetchHTML(data: any) {
    return this.http.post<ApiResponse<any>>(this.API_V2_URL + 'tests/fetchHTML', data, { headers: this.headers });
  }

  resultDetailSubmit(payload: any) {
    return this.http.post<ApiResponse<any>>(this.API_URL + 'tests/result/detail', payload, { headers: this.headers })
  }

  createOrder(payload: any, paymentMethod = null) {
    // if(paymentMethod == "razorpay"){
    //   return this.http.post<ApiResponse<any>>(this.API_URL+'payments/orders', payload, {headers: this.headers});
    // }else{

    // }

    return this.http.post<ApiResponse<any>>(this.API_V2_URL + 'payments/test', payload, { headers: this.headers });

  }

  verifyOrder(payload: any) {
    return this.http.post<ApiResponse<any>>(this.API_V2_URL + 'payments', payload, { headers: this.headers });
  }

  createOrderCoupen(payload: any) {
    return this.http.post<ApiResponse<any>>(this.API_URL + 'promo/apply', payload, { headers: this.headers });
  }

  createOrderCoupen100(payload: any) {
    return this.http.post<ApiResponse<any>>(this.API_V2_URL + 'payments/makepaymentforzero', payload, { headers: this.headers });
  }

  getProfileData() {
    return this.http.get<ApiResponse<any>>(this.API_URL + 'users/mev2', { headers: this.headers });
  }
  getProfileResultPDFData(payload: any) {
    return this.http.post<ApiResponse<any>>(this.API_V2_URL + 'payments/pdf', payload, { headers: this.headers });
  }

  getTestDetail(params: any) {
    params = new HttpParams({ fromObject: params });
    return this.http.get<ApiResponse<any>>(this.API_V2_URL + 'tests/test', { params: params, headers: this.headers });
  }
  getTestDetailById(params: any) {
    params = new HttpParams({ fromObject: params });
    return this.http.get<ApiResponse<any>>(this.API_V2_URL + 'tests/price', { params: params });
  }

  getHomePageData(params: any) {
    params = new HttpParams({ fromObject: params });
    return this.http.get<ApiResponse<any>>(this.API_URL + 'app/home', { params: params });
  }

  getUserHomePageData(params: any) {
    params = new HttpParams({ fromObject: params });
    return this.http.get<ApiResponse<any>>(this.API_URL + 'app/userHome', { params: params });
  }

  getHiringHomePageData(params: any) {
    params = new HttpParams({ fromObject: params });
    return this.http.get<ApiResponse<any>>(this.API_URL + 'app/hiringHome', { params: params });
  }


  getPsycoHomePageData(params: any) {
    params = new HttpParams({ fromObject: params });
    return this.http.get<ApiResponse<any>>(this.API_URL + 'app/psycoHome', { params: params });
  }

  getLanguages() {
    //this.http.post<ApiResponse<any>>(this.getApi('otpverify'), data, {headers: this.headers});
    return this.http.get<ApiResponse<any>>(this.API_URL + 'languages');
  }

  getTestType() {
    //this.http.post<ApiResponse<any>>(this.getApi('otpverify'), data, {headers: this.headers});
    return this.http.get(this.API_URL + 'test-types');
  }

  getTestList() {
    //this.http.post<ApiResponse<any>>(this.getApi('otpverify'), data, {headers: this.headers});
    return this.http.get(this.API_URL + 'tests');
  }

  getQuestions(params: any) {
    params = new HttpParams({ fromObject: params });
    return this.http.get<ApiResponse<any>>(this.API_URL + 'questions/tests', { params: params });
  }

  getResultById(params: any) {
    params = new HttpParams({ fromObject: params });
    return this.http.get(this.API_URL + 'result', { params: params });
  }

  checkPayment(payload: any) {
    return this.http.post<ApiResponse<any>>(this.API_V2_URL + 'payments/unused', payload, { headers: this.headers });
  }
  referTest(payload: any) {
    return this.http.post<ApiResponse<any>>(this.API_V2_URL + 'tests/referTest', payload, { headers: this.headers });
  }

  referDetails(params: any) {
    return this.http.get<ApiResponse<any>>(this.API_V2_URL + 'tests/referDetails', { params: params, headers: this.headers });
  }

  modalFire(msg: string, closable = true, title = "Alert", ok = 'OK') {
    let modal;
    if (typeof document === 'undefined') {
      return; // Exit if not in a browser environment
    }
    if (closable) {
      modal = `<div id="mymodal" class="modal fade show" tabindex="-1" role="dialog" aria-hidden="true" style="background: #808080cc; display: block;">
              <div class="modal-dialog">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h4 class="modal-title">${title}</h4>    
                          <button type="button" class="btn-close" style="cursor: pointer;" onclick="document.querySelector('#mymodal').remove()" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                          <p>${msg}</p>
                      </div>
                      <div class="modal-footer">
                          <button type="button" class="btn btn-primary" style="cursor: pointer;" onclick="document.querySelector('#mymodal').remove()">${ok}</button>
                      </div>
                  </div>
              </div>
          </div>
        `;

    } else {
      modal = `<div id="mymodal" class="modal fade show" tabindex="-1" role="dialog" aria-hidden="true" style="background: #808080cc; display: block;">
              <div class="modal-dialog">
                  <div class="modal-content">
                      <div class="modal-header">
                          <h4 class="modal-title">${title}</h4>
                          <button type="button" class="btn-close" style="cursor: pointer;" onclick="document.querySelector('#mymodal').remove()" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                          <p>${msg}</p>
                        </div>
                    </div>
                </div>
            </div>

        `;

    }

    var div = document.createElement('div'); //container to append to
    div.innerHTML = modal;
    if (div.children.length > 0) {
      document.body.appendChild(div.children[0]);
    }
    //alert(msg);
  }

  alertClose() {
    document?.querySelector('#mymodal')?.remove();
  }

  get nativeWindow(): any {
    return window;
  }
}