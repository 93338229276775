<div class="parent-container">
  <div class="login-container">
    <div class="login-section">
      <div *ngIf="viewType == 'email'">

        <h2>{{ languageService.transalateText("Forgot password?") }}</h2>
        <h6 class="mb-5"> {{ languageService.transalateText("No worries, we’ll send you reset intructions")}}</h6>
      
        <form action="#" method="POST">
          <label for="email">{{languageService.transalateText("Email")}}</label>
          <input type="email" id="email" required [(ngModel)]="formData.user" name="username">

          <button [disabled]="!!!(formData.user)" type="submit"
            (click)="forgetPassword()">{{languageService.transalateText("Get OTP")}}</button>
        </form>
        <h6 class="login-link"><a (click)="login()"><img src="assets/images/back.png" width="24px" height="24px"> {{ languageService.transalateText("Back to Login") }} </a></h6>
      </div>

      <div *ngIf="viewType == 'otp'">
        <h2>{{ languageService.transalateText("Password reset") }}</h2>
        <h6 class="mb-5"> {{ languageService.transalateText("We sent a code to ")}} <span style="color: #201D1D;"> {{formData["user"]}}</span></h6>
        <form action="#" method="POST">
          <div class="otp-container">
            <input type="text" maxlength="1" name="o1" [(ngModel)]="o1" (input)="moveToNext($event, 1)" />
            <input type="text" maxlength="1" name="o2" [(ngModel)]="o2" (input)="moveToNext($event, 2)" />
            <input type="text" maxlength="1" name="o3" [(ngModel)]="o3" (input)="moveToNext($event, 3)" />
            <input type="text" maxlength="1" name="o4" [(ngModel)]="o4" (input)="moveToNext($event, 4)" />
            <input type="text" maxlength="1" name="o5" [(ngModel)]="o5" (input)="moveToNext($event, 5)" />
            <input type="text" maxlength="1" name="o6" [(ngModel)]="o6" (input)="moveToNext($event, 6)" />
          </div>

          <button type="submit"
            (click)="submitOtp()">{{languageService.transalateText("Continue")}}</button>
        </form>
        <p class="login-link">Didn’t receive the email? <a style="color: #198CFB;" (click)="forgetPassword()">Click here</a></p>
        <h6 class="login-link"><a (click)="login()"><img src="assets/images/back.png" width="24px" height="24px"> {{ languageService.transalateText("Back to Login") }} </a></h6>
      </div>

      <div *ngIf="viewType == 'password'">
        <h2>{{ languageService.transalateText("Set new password") }}</h2>
        <h6 class="mb-5"> {{ languageService.transalateText("Good to be at least 8 characters")}}</h6>
        <form action="#" method="POST">
          <label for="password">{{ languageService.transalateText('Password') }}</label>
          <input type="password" id="password" required [(ngModel)]="passwordData.pass" name="password">

          <label for="password">{{ languageService.transalateText('Confirm Password') }}</label>
          <input type="password" id="cpassword" required [(ngModel)]="passwordData.cpass" name="cpass">
          

          <button [disabled]="!!!(passwordData.pass)" type="submit"
            (click)="resetPassword()">{{languageService.transalateText("Reset Password")}}</button>
        </form>
        <h6 class="login-link"><a (click)="login()"><img src="assets/images/back.png" width="24px" height="24px"> {{ languageService.transalateText("Back to Login") }} </a></h6>
      </div>
    </div>
  </div>

  <div class="promo-container">
    <div class="promo-section">
      <h2>
        {{ languageService.transalateText("Choose your own Future") }}
      </h2>
      <p>
        {{
        languageService.transalateText(
        " Comprehensive Psychometric Tests for every aspect of your Well-being"
        )
        }}
      </p>
      <img src="assets/images/logo.png" alt="Illustration" />
    </div>
  </div>
</div>