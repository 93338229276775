import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '../../services/language-service.service';
import { ApiResponse } from '../../model';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-forgetpassword',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './forgetpassword.component.html',
  styleUrl: './forgetpassword.component.scss'
})
export class ForgetpasswordComponent implements AfterViewInit {
  @ViewChild('otpModal') otpModalRef!: ElementRef;
  @ViewChild('resetModal') resetModalRef!: ElementRef;
  formData: any = {};
  routeParams: any;
  o1: string = '';
  o2: string = '';
  o3: string = '';
  o4: string = '';
  o5: string = '';
  o6: string = '';
  otp: string = '';
  passwordData: any = {};
  quizId: number = 0;
  viewType: string = 'email';
  
  constructor(private AS: AuthService, private route: ActivatedRoute, private router: Router, public languageService: LanguageService) {

  }

  moveToNext(event: any, currentInput: number): void {
    const value = event.target.value;
    if (value && currentInput < 6) {
      document.querySelectorAll('input')[currentInput].focus();
    }
  }

  ngOnInit(): void {
    this.initCheckAlreadyLoggedIn();
    this.getRouteParams();
  }

  ngAfterViewInit() {

    // console.log(this.otpDivView);

  }

  initCheckAlreadyLoggedIn() {
    if(this.AS.isLoggedIn()){
      this.router.navigate(['/']);
    }
  }

  getRouteParams() {
    this.route.params.subscribe(params => {
      this.quizId = params['quizId'];
    });
  }

  async login() {
    
      this.router.navigate(['login']);
    
  }

  async forgetPassword() {
    if(!this.formData['user']){
      this.AS.alert("Email required")
      return
    }
    let credential = { 'username': this.formData['user'] };

    this.AS.forgetPassword(credential).subscribe({
      next: (res: ApiResponse<any>) => {
        // console.log('forget Password:', res);
        let checkRes = this.AS.checkRes(res);
        if (checkRes == 'data') {
          if (res['status'] == 'success') {
            this.viewType = 'otp';
            // this.showOtpModal();
          } else {
            this.AS.alert('username incorrect');
          }
        } else {
          this.AS.alert('Account not registered!');
        }
      },
      error: (err) => {
        this.AS.alert('Internet Error! please check your internet');
        console.log('Err:', err);
      },
      complete: () => {
        // Optionally handle the completion
      }
    });
    

  }

  submitOtp() {
    this.otp = this.o1+this.o2+this.o3+this.o4+this.o5+this.o6
    if (!this.otp || this.otp.length < 3) {
      this.AS.alert("OTP should be 6 digit");
      return
    }
    let payload = { 'username': this.formData['user'], 'otp': this.otp };
    this.AS.confirmOtp(payload).subscribe({
      next: (res: ApiResponse<any>) => {
        let checkRes = this.AS.checkRes(res);
        if (res['status'] == 'success') {
          // this.closeOtpModal();
          // this.showResetModal();
          this.viewType = 'password'
        } else {
          this.AS.alert('Invalid Otp');
        }
      },
      error: (err) => {
        this.AS.alert('Internet Error! please check your internet');
        console.log('Err:', err);
      },
      complete: () => {
        // Optionally handle the completion
      }
    });
    return
  }

  // closeModal() {
  //   this.otpDivView.nativeElement.classList.add('hidden');
  //   this.otpDivView.nativeElement.classList.remove('show');
  // }

  resetPassword() {
    let validateForm = this.passwordValidation();
    if (validateForm == false) {
      this.AS.alert('password & confirm password mismatch');
      return;
    }
    let payload = {
      username: this.formData['user'],
      otp: this.otp,
      password: this.passwordData.pass
    }

    this.AS.resetPassword(payload).subscribe(res => {

      let checkRes = this.AS.checkRes(res);
      if (res['status'] == 'success') {
        this.router.navigate(['/login']);

      } else {
        this.AS.alert('Error while reseting password');
      }


    }, (err) => {
      this.AS.alert('Internet Error! please check your internet');
      console.log('Err:', err);
    })



  }

  passwordValidation() {
    if (this.passwordData.pass == this.passwordData.cpass) {
      if (this.passwordData.pass.length > 0 && this.passwordData.cpass.length > 0) {
        return true;
      }
    }

    return false;
  }



}
