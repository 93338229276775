<div class="profile-container">
  <div class="profile-header">
    <img src="/assets/images/user.png" alt="Profile Image" class="profile-img" />
    <h3 class="profile-name">{{user.name}}</h3>
    <p class="profile-email">{{user.username}}</p>
  </div>

  <div class="tab-section">
    <span class="tab-label {{activeTab == 'profile'?'active':''}}"><a
        (click)="switchTab('profile')">{{languageService.transalateText('Profile overview')}}</a></span>
    <span class="tab-label {{activeTab == 'test'?'active':''}}"><a
        (click)="switchTab('test')">{{languageService.transalateText('My Tests')}}</a></span>
  </div>
  <div style="display: flex;justify-content: center;">
    <div class="profileOverview mt-5" *ngIf="activeTab == 'profile'">
      <div class="profile-section">
        <h3>{{languageService.transalateText("Personal Information")}}:</h3>
        <p><span>{{languageService.transalateText("Full Name")}}:</span><span>{{user.name}}</span></p>
        <p><span>{{languageService.transalateText("Email")}}:</span><span>{{user.username}}</span></p>
      </div>

      <div class="profile-section">
        <h3>{{languageService.transalateText("Account settings")}}:</h3>
        <p><span>{{languageService.transalateText("Password")}}:</span><span><a style="cursor: pointer;" (click)="resetPassword()"><img
                src="/assets/images/edit.png">{{languageService.transalateText("Edit")}}</a></span></p>
      </div>

      <!-- <div class="profile-section">
        <h3>Preferences:</h3>
        <p><span>Language:</span><span>English</span></p>
      </div> -->
      <div class="profile-section">
        <p><span class="logout">{{languageService.transalateText('Logout')}}</span><span><a style="cursor: pointer;"
              (click)="logOut()"><img src="/assets/images/logout.png"></a></span></p>
      </div>
    </div>
  </div>
  <div *ngIf="activeTab == 'test'">
    <table class="test-table">
      <thead>
        <tr>
          <th>{{languageService.transalateText("Date")}}</th>
          <th>{{languageService.transalateText("Tests")}}</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of profileResults">
          <td>{{result.r_time}}</td>
          <td>{{result.title}}</td>
          <td><a (click)="viewResult(result.html_url,result.id)" title="View Result" class="view-report"><i
                class="fa fa-eye"></i> <span class="text-big">{{languageService.transalateText("View Report")}}</span></a></td>
          <td *ngIf="result.test_type_id<=5"><a (click)="downloadResultPDF(result.pdf_url,result.id)"
              title="Download Result" class="download-link"><i class="fa fa-download"></i> <span
                class="text-big">{{languageService.transalateText("Download")}}</span></a></td>
          <td *ngIf="result.test_type_id>5"><a (click)="viewResult(result.html_url,result.id)"
              title="Download Result" class="download-link"><i class="fa fa-download"></i> <span
                class="text-big">{{languageService.transalateText("Download")}}</span></a></td>
        </tr>
        <!-- Repeat similar rows as needed -->
      </tbody>
    </table>
  </div>
</div>