import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { AppFooterComponent } from "./components/app-footer/app-footer.component";
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { AuthService } from './services/auth.service';
import * as Sentry from "@sentry/angular"

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, AppHeaderComponent, AppHeaderComponent, AppFooterComponent, CommonModule, LoaderComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'dk-frontend';
  constructor(public router: Router, private AS: AuthService){
    let user = this.AS.userCTRL('get');
    if(user?.username){
      Sentry.setUser({email: user?.username})
    }
  }
}
