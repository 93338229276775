import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-view-result',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './view-result.component.html',
  styleUrl: './view-result.component.scss'
})
export class ViewResultComponent implements OnInit {
  pdfUrl: SafeResourceUrl | null = null;
  resultUrl: SafeResourceUrl | null = null;
  constructor(private authService: AuthService, private sanitizer: DomSanitizer, private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.getRouteParams();
  }

  getRouteParams() {
    this.route.queryParams.subscribe({
      next: (params) => {
        if (params['pdfUrl']) {
          this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(params['pdfUrl']);
        } else if(params['resultUrl']){
          this.resultUrl = this.sanitizer.bypassSecurityTrustResourceUrl(params['resultUrl'])
        }
      },
      error: (err) => {
        this.authService.alert('Unable to load your result. Contact support');
        console.error('Err:', err);
      },
      complete: () => {
        // Optional: Add any logic that needs to be executed after successful completion of the outer subscription
      }
    });
  }
}
