import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  
  if (authService.isLoggedIn()) {
    return true;
  } else {
    const attemptedUrl = state.url;
    if (state.url.includes('psycologist')) {
      router.navigate(['psycologistRegister'], { queryParams: { returnUrl: attemptedUrl } });
    } else {
      router.navigate(['/register'], { queryParams: { returnUrl: attemptedUrl } });
    }
    return false;
  }
};
