import {
  HttpRequest,
  HttpHandlerFn,
  HttpEvent,
  HttpInterceptorFn,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { LoaderService } from './loader.service'; // Make sure LoaderService is imported

export const apiInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const loaderService = inject(LoaderService); // Inject LoaderService here

  const token = authService.sessionCTRL('get') || null;

  const requestHmac = generateHmac(req);
  let newHeaders = req.headers
    .append('api_version', environment.api_version)
    .append('device', 'Web')
    .append('request-signature', requestHmac.hmac)
    .append('request-timestamp', requestHmac.requestTimestamp)
    .append('user-location', authService.commandinDB('get', 'userLocation') || '');

  if (req.url.indexOf('zoho') === -1 && token) {
    newHeaders = newHeaders.append('auth-token', token);
  }

  const authReq = req.clone({ headers: newHeaders });

  // Show the loader when the API call starts
  loaderService.showLoader();

  return next(authReq).pipe(
    map((resp: HttpEvent<any>) => {
      if (resp instanceof HttpResponse) {
        return resp;
      }
      return resp;
    }),
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        console.log('Hidden Error =>', error);
        authService.loggedOut();
        authService.alert('Logged Out');
        router.navigate(['/login']);
      }
      return throwError(() => error);
    }),
    finalize(() => {
      // Hide the loader when the API call completes (whether success or error)
      loaderService.hideLoader();
    })
  );
};

const generateHmac = (req: HttpRequest<any>) => {
  const requestTimestamp = new Date().toISOString();
  const requestMethod = req.method.toLowerCase();
  const requestUrl = new URL(req.urlWithParams);
  const requestPath = requestUrl.pathname + requestUrl.search;

  const requestString = `${requestMethod}-${requestPath}-${requestTimestamp}`;

  const hmac = CryptoJS.HmacSHA256(requestString, environment.key).toString();
  return { requestTimestamp, hmac };
};
