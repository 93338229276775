import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language-service.service';
import { AuthService } from '../../services/auth.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResponse } from '../../model';
import { PaymentDialogComponent } from '../dialogs/payment-dialog/payment-dialog.component';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-psycologist-refer-test',
  standalone: true,
  imports: [FormsModule, CommonModule, PaymentDialogComponent],
  templateUrl: './psycologist-refer-test.component.html',
  styleUrl: './psycologist-refer-test.component.scss'
})
export class PsycologistReferTestComponent implements OnInit {
  showAgeSelection: boolean = false;
  selectedAge: string = '13';
  clientAccepted = false;
  psychologistAccepted = false;
  userType: string = '';
  clientEmail: string = '';
  paymentMode: string = '';
  testDetails: any = {};
  testType: number = 0;
  paymentExisted: boolean = false;
  selectedLanguage: string = '';
  showPaymentModal: boolean = false;
  detailModal: boolean = false;

  constructor(public languageService: LanguageService, private AS: AuthService, private route: ActivatedRoute, private router: Router,private alertService:AlertService) {
    this.selectedLanguage = <string>this.AS.commandinDB('get', 'selectedLanguage') || this.AS.DefaultLanguage;
  }

  ngOnInit(): void {
    this.extractTestType()
  }

  extractTestType() {
    this.route.params.subscribe(params => {
      // console.log('Route Params:', params); // Log the entire params object
      this.testType = params['testType']; // Extract routeData parameter from URL
      // console.log('Test Type:', this.testType);
      if (this.testType) {
        this.initialiseTest(this.testType);
      } else {
        this.AS.alert('Unable to fetch test');
      }

    });
  }

  initialiseTest(quizId: number) {
    let payload = {
      testTypeId: quizId
    }
    this.AS.checkPayment(payload).subscribe({
      next: (res: ApiResponse<any>) => {
        if (res['status'] == 'success') {
          this.paymentExisted = true;
        }
        this.selectedAge = '13';
        this.refreshTestDetails(); // Assuming this is a method
      },
      error: (err: any) => {
        this.AS.alert('Internet Error! please check your internet');
        console.error('Err:', err);
      },
      complete: () => {
        // Optional complete callback
      }
    });
  }

  refreshTestDetails() {
    let params = {
      languageId: this.selectedLanguage,
      testTypeId: this.testType,
      age: this.selectedAge
    }
    this.AS.getTestDetail(params).subscribe({
      next: (res: ApiResponse<any>) => {
        const checkRes = this.AS.checkRes(res);

        if (checkRes === 'data') {
          if (res['status'] === 'success') {
            this.testDetails = res['data'];
          } else {
            this.AS.alert('Response success failed');
          }
        } else {
          this.AS.alert('Test not found');
          this.selectedLanguage = this.AS.DefaultLanguage;
        }
      },
      error: (err: any) => {
        this.AS.alert('Internet Error! please check your internet');
        console.error('Err:', err);
      },
      complete: () => {
        // Optional: Add any logic that needs to be executed after successful completion (if applicable)
      }
    });

  }

  submit() {
    if (!this.psychologistAccepted) {
      this.AS.alert('Psycologist Acceptance required');
      return
    }

    if (!this.clientEmail) {
      this.AS.alert('Client Email required');
      return
    }

    if (this.paymentMode == 'client') {
      this.referTest();
    } else if (this.paymentMode == 'self') {
      if (this.paymentExisted) {
        this.referTest()
      } else {
        
        this.showPaymentModal = true;
        this.detailModal = false;
      }
    } else{
      this.AS.alert("Choose payment mode first")
    }

  }

  closeModal(): void {
    this.showPaymentModal = false;
  }

  closeDetailModal(): void {
    this.detailModal = false;
  }

  continueToDetail(){
    this.detailModal = true;
  }


  paymentSuccess() {
    this.showPaymentModal = false;
    this.referTest();
  }

  referTest() {
    let payload = {
      clientMail: this.clientEmail,
      paymentMode: this.paymentMode,
      testTypeId: this.testType,
      testName: this.testDetails?.test_title,
      psycologistName: this.AS.userCTRL('get')?.fullname
    };
    this.AS.referTest(payload).subscribe({
      next: (res: ApiResponse<any>) => {
        if (res['status'] == 'success') {
          // this.paymentExisted = true;
          this.alertService.showSuccessAlert("Test referral successful. An email has been sent to the client with requisite instructions.");
          this.router.navigate(['psycologist-home'])
          return
        }
        
      },
      error: (err: any) => {
        this.AS.alert('Internet Error! please check your internet');
        console.error('Err:', err);
      },
      complete: () => {
        // Optional complete callback
      }
    });
  }

}
