import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language-service.service';

@Component({
  selector: 'app-app-footer',
  standalone: true,
  imports: [],
  templateUrl: './app-footer.component.html',
  styleUrl: './app-footer.component.scss'
})
export class AppFooterComponent implements OnInit {
  DMRC_path: string = '';
  tnc_path: string = '';
  privacy_policy_path: string = '';
  disclamer_path: string = '';
  dmca_img_path: string = '';
  helper_script: string = '';

  constructor(public languageService: LanguageService) {
   
  }

  ngOnInit(): void {
    this.helper_script = "https://images.dmca.com/Badges/DMCABadgeHelper.min.js";
    this.DMRC_path = "//www.dmca.com/Protection/Status.aspx?ID=751f3f88-3989-430d-9de4-069b8533e801";
    this.tnc_path = "https://dishakiran.com/terms-conditions/";
    this.privacy_policy_path= "https://dishakiran.com/privacy-policy/";
    this.disclamer_path = "https://dishakiran.com/disclaimer/";
    this.dmca_img_path = "https://images.dmca.com/Badges/dmca_protected_sml_120n.png?ID=751f3f88-3989-430d-9de4-069b8533e801";
  }
}
