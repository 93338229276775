<div class="container">
  <div class="row" style="padding-bottom: 50px;">
    <div class="col-md-12">

      <h3 class="text-center main-heading">
        {{languageService.transalateText('Get started')}}</h3>
      <p class="text-center sub-heading">
        {{languageService.transalateText('Choose your path to begin your tests')}}</p>

      <!-- Language Select Box -->
      <div class="row">
        <div class="cardcustom text-center">
          <p class="card-text">{{languageService.transalateText('For Users')}}</p>
          <div class="card-body" style="justify-content: center;display: flex;">
            <div class="card text-center inner-card" >
              <div class="card-body"><img src="assets/images/teamwork.png" alt="User" class="img-fluid"></div>
            </div>
          </div>
          
          <p class="desc-txt">{{languageService.transalateText('Explore the assessments and understand your mental health and personal strengths')}}</p><br>
            <button class="start-btn" (click)="goToUserHome()">{{languageService.transalateText('Start')}}</button>
        </div>

        <div class="cardcustom text-center">
          <p class="card-text">{{languageService.transalateText('For Psychologists')}}</p>
          <div class="card-body" style="justify-content: center;display: flex;">
            <div class="card text-center inner-card">
              <div class="card-body"><img src="assets/images/girl1.png" alt="Psychologist" class="img-fluid"></div>
            </div>
          </div>         
          <p class="desc-txt"> {{languageService.transalateText('Access Professional Assessment Tools and provide clinical assessments')}}</p><br>
          <button class="start-btn" (click)="goToPsycoHome()">{{languageService.transalateText('Start')}}</button>
        </div>
      </div>


    </div>

    <!-- <div class="col-md-6 offset-md-1 right-panel d-none d-md-flex">
        <h1 class="text-center" style="font-size: 40px;font-weight: 600;">{{languageService.transalateText('Choose your own Future')}}</h1>
        <p class="text-center" style="font-size: 20px;">{{languageService.transalateText('Comprehensive Psychometric Tests for every aspect of your Well-being')}}</p>
        <img src="assets/images/logo.png" alt="Assessment" class="img-fluid mt-4">
      </div> -->
  </div>
</div>