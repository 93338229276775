<h2 mat-dialog-title class="dialog-title">{{data.title}}</h2>
<mat-dialog-content>
    <p *ngIf="data.subTitle">
        {{data.subTitle}}
    </p>
    <div class="check-icon">
        <mat-icon class="success-icon">error</mat-icon>

    </div>
    <p>
        {{data.message}}
    </p>
</mat-dialog-content>

<button mat-icon-button class="close-btn" mat-dialog-close>
    <mat-icon>close</mat-icon>
</button>