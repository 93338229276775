<div class="parent-container">
  <div class="login-container">
      <div class="login-section">
          <h2>{{languageService.transalateText("Login")}}</h2>
          <form action="#" method="POST">
              <label for="email">{{languageService.transalateText("Email")}}</label>
              <input type="email" id="email" required [(ngModel)]="formData.user" name="username">

              <label for="password">Password</label>
              <div class="password-wrapper">

                  <input type="password" [(ngModel)]="formData.pass" id="password" name="password"  required >
                  <!-- <i class="fa fa-eye toggle-password" (click)="togglePasswordVisibility()"></i> -->
                  <a (click)="togglePasswordVisibility()"><img src="/assets/images/eye.png" class="toggle-password"></a>
              </div>


              <a (click)="forgetPassword()" class="forgot-password">{{languageService.transalateText("Forgot password?")}}</a>

              <button [disabled]="!!!(formData.user && formData.pass)" type="submit" (click)="login()">{{languageService.transalateText("Continue")}}</button>
          </form>
          <p class="signup-link">{{languageService.transalateText("Don't have an account? ")}}<a (click)="register()">{{languageService.transalateText("Register Here")}}</a></p>
      </div>
  </div>

  <div class="promo-container ">
      <div class="promo-section">
          <h2>{{languageService.transalateText("Choose your own Future")}}</h2>
          <p>{{languageService.transalateText("Comprehensive Psychometric Tests for every aspect of your Well-being")}}</p>
          <img src="assets/images/logo.png" alt="Illustration">
      </div>
  </div>
</div>
