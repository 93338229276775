<div class="inst-container">
    <main>
        <div class="row">
            <div class="col-9">
                <section class="test-header">
                    <h2>{{testDetails.category_title}}</h2>
                    <h1>{{testDetails.test_title}}</h1>
                    <p class="description">{{testDetails.test_desc}}</p>
                    <div class="test-info">
                        <span>📝 {{testDetails.questionCount}} {{languageService.transalateText('questions')}} </span>
                        <span *ngIf="testDetails.timer">⏳ {{testDetails.timer/60}} {{languageService.transalateText('minutes')}} </span>
                    </div>
                </section>
            </div>
            <div class="col-3">
                <img [src]="testDetails.question_img" class="test-img">
            </div>
        </div>
        <div class="break-line1 mt-1 mb-4"></div>
        <section class="test-details" [innerHTML]="testDetails.disclaimer_body">
        </section>
        <!-- <div class="mt-2 age-select" *ngIf="showAgeSelection">

            <input type="radio" value="13" [(ngModel)]="selectedAge" (ngModelChange)="refreshTestDetails()" id="13"
                name="age" class="answer">
            <label for="13">
                &nbsp;{{languageService.transalateText('Below 18 Years')}}
                &nbsp;&nbsp;&nbsp;&nbsp;
            </label>
            <br>
            <input type="radio" value="18" id="13+" [(ngModel)]="selectedAge" (ngModelChange)="refreshTestDetails()"
                name="age" class="answer">
            <label for="13+">
                &nbsp;{{languageService.transalateText('18 Years or Above')}}
            </label>

        </div> -->

        <!-- <div *ngIf="userType=='user'">
            <input type="checkbox" id="clientAcceptance" [(ngModel)]="clientAccepted"> Client Acceptance
        </div>

        <br> -->
        <div>
            <h4>{{languageService.transalateText("Psychology Acceptance")}}</h4>
            <input type="checkbox" id="psychologistAcceptance" [(ngModel)]="psychologistAccepted"> {{languageService.transalateText("I acknowledge and agree to the terms related to psychology services.")}}
        </div>
    </main>

    <!-- <div class="mt-2 age-select">
        <h4>{{languageService.transalateText("Payment Mode")}}</h4>
        <input type="radio" value="client" [(ngModel)]="paymentMode" id="13" name="payment" class="answer">
        <label for="13">
            &nbsp;{{languageService.transalateText('Client')}}
            &nbsp;&nbsp;&nbsp;&nbsp;
        </label>
        <br>
        <input type="radio" value="self" id="13+" [(ngModel)]="paymentMode" name="payment" class="answer">
        <label for="13+">
            &nbsp;{{languageService.transalateText('Self')}}
        </label>

    </div> -->

    <div class="start-button-container">
        <!-- <input type="email" id="clientEmail" [(ngModel)]="clientEmail"><br> -->
        <button class="start-button" [disabled]="!psychologistAccepted" (click)="continueToDetail()">Continue</button>
    </div>

    <div class="modal" *ngIf="showPaymentModal">
        <div class="modal-content">
            <span class="close" (click)="closeModal()">&times;</span>

            <!-- Child component -->
            <app-payment-dialog [testTypeId]="testType" (success)="paymentSuccess()"></app-payment-dialog>
        </div>
    </div>

    <div class="modal" *ngIf="detailModal">
        <div class="modal-content payment-modal">
            <span class="close" (click)="closeDetailModal()">&times;</span>
    
            <h3>{{languageService.transalateText('Select how the payment for this test will be made.')}}</h3>
            
            <div class="payment-options">
                <input type="radio" value="self" [(ngModel)]="paymentMode" id="self" name="payment" class="answer">
                <label for="self">{{languageService.transalateText('I will pay the fees')}}</label>
                <br>
                <input type="radio" value="client" [(ngModel)]="paymentMode" id="client" name="payment" class="answer">
                <label for="client">{{languageService.transalateText('Client will pay the fees')}}</label>
            </div>
    
            <div class="email-input">
                <input type="email" id="clientEmail" [(ngModel)]="clientEmail" placeholder="{{languageService.transalateText('Enter client\'s email address')}}">
                <p>{{languageService.transalateText('An email will be sent to the client with test instructions')}}</p>
            </div>
    
            <button class="confirm-button" [disabled]="!clientEmail && !paymentMode" (click)="submit()">
                {{languageService.transalateText('Confirm')}}
            </button>
        </div>
    </div>
    
</div>