<div class="payment-container">
    <div class="payment-box">
      <h2>{{languageService.transalateText("Payment")}}</h2>
      
      <div class="coupon">
        <label for="coupon" class="mb-1">{{languageService.transalateText('Have a coupon code? Apply here')}}</label>
        <div class="coupon-input">
          <input [(ngModel)]="formData.coupen" type="text" id="coupon" placeholder="{{languageService.transalateText('Enter Coupon Code')}}" />
          <button (click)="callCoupenAPI()"  class="apply-btn {{promoApplied?'applied':''}}">{{ promoApplied?languageService.transalateText('Applied'): languageService.transalateText('Apply')}}</button>
        </div>
      </div>
  
      <div class="summary">
        <h3>{{languageService.transalateText('Summary')}}:</h3>
        <div class="summary-details">
          <div class="item">
            <span>{{languageService.transalateText("Test name")}}</span>
            <span>{{testResultData.test_title}}</span>
          </div>
          <div class="item">
            <span>{{languageService.transalateText("Quantity")}}</span>
            <span>{{quantity}}{{languageService.transalateText(" tests")}} </span>
          </div>
          <div class="item">
            <span>{{languageService.transalateText('Payable Amount')}}</span>
            <span>₹{{basicAmount}}</span>
          </div>
          <div class="item" *ngIf="discount > 0">
            <span>{{languageService.transalateText('Coupon Applied')}}</span>
            <span>-₹{{discount}}</span>
          </div>
          <div class="item total-price">
            <span>{{languageService.transalateText('Total Amount')}}</span>
            <span>₹{{amount}}</span>
          </div>
        </div>
      </div>
  
      <button (click)="initOrder()" class="payment-btn">{{languageService.transalateText('Proceed to pay')}}</button>
    </div>
  </div>
  