<div class="header_top">
    <div class="container">
        <div class="header_top_left">
            <a href="https://dishakiran.com" target="_blank">
                <img src="assets/images/logo_m.png" class="header-logo" alt="Disha Kiran Logo">

            </a>
            <!-- <a href="https://dishakiran.com" target="_blank">
                <div>
                    <h3 style="margin: 0; color: #ffffff; font-size: 20px;">Disha Kiran</h3>
                    <p style="margin: 0; color: #ffffff; font-size: 13px;">Build your own life</p>
                </div>
            </a> -->
            

        </div>

        <div class="header_top_right">
            <ul class="header_menu">
                <li class="header-text">
                    <a href="/">{{languageService.transalateText('HOME')}}</a>
                </li>
                <li class="header-text">
                    <a href="/profile">{{languageService.transalateText('PROFILE')}}</a>
                </li>
                <li class="header-text">
                    <select class="form-control language-select" [(ngModel)]="selectedLanguage"
                        (ngModelChange)="setUserLanguage()">
                        <ng-container *ngFor="let l of languages">
                            <option *ngIf="l.is_active" value="{{l.id}}">{{l.language}}</option>
                        </ng-container>
                    </select>
                </li>
            </ul>

            <!-- Hamburger Icon -->
            <div class="hamburger" (click)="toggleSidePanel()">
                &#9776;
            </div>
        </div>
    </div>
</div>

<!-- Side Panel -->
<div class="side-panel" [class.open]="isSidePanelOpen">
    <a href="javascript:void(0)" class="close-btn" (click)="toggleSidePanel()">&times;</a>
    <a href="/">{{languageService.transalateText('HOME')}}</a>
    <a href="/profile">{{languageService.transalateText('PROFILE')}}</a>
    <select class="form-control language-select" [(ngModel)]="selectedLanguage" (ngModelChange)="setUserLanguage()">
        <ng-container *ngFor="let l of languages">
            <option *ngIf="l.is_active" value="{{l.id}}">{{l.language}}</option>
        </ng-container>
    </select>
</div>