<div class="parent-container">
    <div class="register-container">
        <div class="register-section">
            <h2>{{languageService.transalateText("Register as Psychologist")}}</h2>
            <form action="#" method="POST">
                <label for="name">{{languageService.transalateText("Full Name")}}</label>
                <input type="text" id="name" required [(ngModel)]="formData.name" name="name">
                <label for="email">{{languageService.transalateText("Email")}}</label>
                <input type="email" id="email" required [(ngModel)]="formData.user" name="username">

                <label for="phone">{{languageService.transalateText("Phone Number")}}</label>
                <input style="width: 100% !important;" type="tel" [(ngModel)]="formData.phone" id="phone" name="phone" appIntlTelInput [initialCountry]="'in'" (fullPhoneNumber)="setFullPhone($event)" required>

                <label style="margin-top: 20px;" for="qualification">{{languageService.transalateText("Qualification")}}</label>
                <input type="text" [(ngModel)]="formData.qualification" id="qualification" name="qualification" required>


                <label for="password">{{languageService.transalateText("Password")}}</label>
                <input type="password" [(ngModel)]="formData.pass" id="password" name="password" required>

                <label for="password1">{{languageService.transalateText("Confirm Password")}}</label>
                <input type="password" [(ngModel)]="formData.cpass" id="password1" name="password1" required>
                <div class="tnc">{{languageService.transalateText("By clicking")}},
                    {{languageService.transalateText("you agree to the")}} <a
                        href="https://dishakiran.com/terms-conditions/"
                        target="_blank">{{languageService.transalateText("Terms and Conditions")}}</a></div>
                <button type="submit" (click)="register()">{{languageService.transalateText("Create Account")}}</button>
            </form>
            <p class="signup-link">{{languageService.transalateText("Already have an account?")}} <a
                    (click)="login()">{{languageService.transalateText("Login Here")}}</a></p>
        </div>
    </div>

    <div class="promo-container ">
        <div class="promo-section">
            <h2>{{languageService.transalateText("Choose your own Future")}}</h2>
            <p>{{languageService.transalateText("Comprehensive Psychometric Tests for every aspect of your
                Well-being")}}</p>
            <img src="assets/images/logo.png" alt="Illustration">
        </div>
    </div>
</div>